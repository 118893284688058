import { Fragment, useReducer } from 'react';

import usePeriodComparison from '~/hooks/usePeriodComparison';
import useRestriction from '~/hooks/useRestriction';
import useSimulationRules from '~/pages/Portfolio/useSimulationRules';

import SimulationSection from '../SimulationSection';
import { TableColumn, TableHeading, TableHorizontalAxis, TableSubheading, TableVerticalAxis } from '../Table';
import { useSimulationTable } from '../withSimulationTable';

const subject = {
  cceeAmount: 'Liquidação CCEE',
  argentinaBalanceAmount: 'Exportação ARG',
  uruguaiBalanceAmount: 'Exportação URU',
  walletBalanceAmount: 'Carteira',
  predictedBalanceAmount: 'Saldo Total',
  specialBallast: 'Lastro especial (MWm)',
  notSpecialBallast: 'Lastro não especial (MWm)',
  globalBallast: 'Lastro global (MWm)',
};

function SimulationDRE() {
  const { simulationPeriods } = useSimulationTable();
  const { isPortfolio } = useSimulationRules();
  const [isOpen, toggle] = useReducer((prev) => !prev, false);
  const getColor = usePeriodComparison();
  const { restrict } = useRestriction();

  const getNumberFormat = (
    value: number,
    options: Intl.NumberFormatOptions = {
      currency: 'BRL',
      style: 'currency',
      minimumFractionDigits: 0,
    },
  ) => new Intl.NumberFormat('pt-BR', options).format(value);

  return (
    <>
      <SimulationSection label="Resultado" />
      <TableHorizontalAxis>
        <TableVerticalAxis position="sticky" left="0" gridTemplateColumns="200px" zIndex="1">
          {Object.keys(subject).map((operation) => {
            const isPredictable = /predictedBalanceAmount/.test(operation);

            return (
              <Fragment key={operation}>
                <TableHeading
                  fontWeight={/predictedBalanceAmount/.test(operation) ? 600 : 400}
                  isOpen={isPredictable && isOpen}
                  onToggle={operation === 'predictedBalanceAmount' ? toggle : undefined}
                >
                  {subject[operation]}
                </TableHeading>
                {isPredictable && isOpen && (
                  <>
                    <TableSubheading>Cenário alto</TableSubheading>
                    <TableSubheading>Cenário baixo</TableSubheading>
                    <TableSubheading>Curva Dcide</TableSubheading>
                    <TableSubheading>Curva Bbce</TableSubheading>
                    {!isPortfolio &&
                      restrict(
                        'SIMULATION_INTEREST_RATE',
                        <>
                          <TableSubheading>Fator de desconto</TableSubheading>
                          <TableSubheading>Valor presente</TableSubheading>
                        </>,
                      )}
                  </>
                )}
              </Fragment>
            );
          })}
        </TableVerticalAxis>
        {simulationPeriods?.map(({ period, dre }) => {
          const color = getColor(period);

          return (
            <TableVerticalAxis key={period} bgColor={color}>
              <TableColumn>{getNumberFormat(dre.cceeAmount)}</TableColumn>
              <TableColumn>{getNumberFormat(dre.argentinaBalanceAmount)}</TableColumn>
              <TableColumn>{getNumberFormat(dre.uruguaiBalanceAmount)}</TableColumn>
              <TableColumn>{getNumberFormat(dre.walletBalanceAmount)}</TableColumn>
              <TableColumn emphasis>{getNumberFormat(dre.predictedBalanceAmount)}</TableColumn>
              {isOpen && (
                <>
                  <TableColumn>{getNumberFormat(dre['highBalanceAmount'])}</TableColumn>
                  <TableColumn>{getNumberFormat(dre['lowBalanceAmount'])}</TableColumn>
                  <TableColumn>{getNumberFormat(dre['dcideBalanceAmount'])}</TableColumn>
                  <TableColumn>{getNumberFormat(dre['bbceBalanceAmount'])}</TableColumn>
                  {!isPortfolio &&
                    restrict(
                      'SIMULATION_INTEREST_RATE',
                      <>
                        <TableColumn>
                          {Intl.NumberFormat('pt-br', { minimumFractionDigits: 6, maximumFractionDigits: 6 }).format(
                            dre['pvFactor'],
                          )}
                        </TableColumn>
                        <TableColumn>{getNumberFormat(dre['pvTotalAmount'])}</TableColumn>
                      </>,
                    )}
                </>
              )}
              <TableColumn>{getNumberFormat(dre.specialBallast, { minimumFractionDigits: 2 })}</TableColumn>
              <TableColumn>{getNumberFormat(dre.notSpecialBallast, { minimumFractionDigits: 2 })}</TableColumn>
              <TableColumn>{getNumberFormat(dre.globalBallast, { minimumFractionDigits: 2 })}</TableColumn>
            </TableVerticalAxis>
          );
        })}
        <TableVerticalAxis gridTemplateColumns="initial" width="100%">
          <TableColumn showSkeleton={false}>&nbsp;</TableColumn>
          <TableColumn showSkeleton={false}>&nbsp;</TableColumn>
          {isOpen && (
            <>
              <TableColumn showSkeleton={false}>&nbsp;</TableColumn>
              <TableColumn showSkeleton={false}>&nbsp;</TableColumn>
            </>
          )}
          <TableColumn showSkeleton={false}>&nbsp;</TableColumn>
          <TableColumn showSkeleton={false}>&nbsp;</TableColumn>
          <TableColumn showSkeleton={false}>&nbsp;</TableColumn>
        </TableVerticalAxis>
      </TableHorizontalAxis>
    </>
  );
}

export default SimulationDRE;
