import { gql } from '@apollo/client';

import { BALANCE_FRAGMENT } from './simulation';

const NEW_PORTFOLIO = gql`
  ${BALANCE_FRAGMENT}

  query newPortfolio(
    $startYear: String!
    $endYear: String
    $energyType: [String!]
    $periodType: QuotePeriod
    $priceType: [QuotePrice!]
    $expandedPeriods: [String!]
    $excludedContracts: [ID!]
    $tenantId: [ID!]
    $internalAccounts: [ID!]
    $usageFactorByContract: Boolean
    $usageFactorByEnergyType: Boolean
    $usageFactor: Boolean
    $giro: String
    $hasGiro: Boolean
    $contractFlags: [String!]
    $accountingPortfolio: ContractPortfolio
    $closeDateLte: ISO8601Date
    $closeDateGte: ISO8601Date
    $accountTypes: [String!]
  ) {
    simulation: newPortfolio(
      startYear: $startYear
      endYear: $endYear
      energyType: $energyType
      periodType: $periodType
      priceType: $priceType
      expandedPeriods: $expandedPeriods
      excludedContracts: $excludedContracts
      tenantId: $tenantId
      internalAccounts: $internalAccounts
      usageFactorByContract: $usageFactorByContract
      usageFactorByEnergyType: $usageFactorByEnergyType
      usageFactor: $usageFactor
      giro: $giro
      hasGiro: $hasGiro
      contractFlags: $contractFlags
      accountingPortfolio: $accountingPortfolio
      closeDateLte: $closeDateLte
      closeDateGte: $closeDateGte
      accountTypes: $accountTypes
    ) {
      simulation {
        id
        name
        conventionalUsageFactor
        incentivizedUsageFactor
        user {
          id
          name
        }
        createdAt
        updatedAt
        synchronizedAt
      }
      simulationPeriods {
        period
        balance {
          quantityMwm: balanceMwm
          totalAmount
          originalTotalAmount
          purchase {
            ...balanceOperation
          }
          sale {
            ...balanceOperation
          }
        }
        dre {
          cceeAmount
          argentinaBalanceAmount
          uruguaiBalanceAmount
          walletBalanceAmount
          predictedBalanceAmount
          highBalanceAmount
          lowBalanceAmount
          dcideBalanceAmount
          bbceBalanceAmount
          specialBallast
          notSpecialBallast
          globalBallast
        }
        exposure {
          n {
            ...exposureSubmarket
          }
          ne {
            ...exposureSubmarket
          }
          s {
            ...exposureSubmarket
          }
          se {
            ...exposureSubmarket
          }
        }
      }
      simulationOperations {
        id
        createdAt
        energyType
        operation
        submarket
        updatedAt
        simulationItems {
          competence
          quantityMwm
          price
        }
      }
    }
  }
`;

export default NEW_PORTFOLIO;
